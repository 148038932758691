export default {
	ENV: 'production',
	APP_ID: 'af34d99f5e486e42df365d60298f1ef8',
	// APP_URL: 'https://dashboard.medidatewith.me',
	APP_URL: 'https://admin.medidatewith.me',
	SERVER_URL: 'https://medidate.herokuapp.com',
	PARSE_SERVER_URL: 'https://medidate.herokuapp.com/parse/',
	LIVE_QUERY_SERVER_URL: 'wss://medidate.herokuapp.com/parse/',
	GOOGLE_MAPS_KEY: 'AIzaSyBXe3KZgp2JghIVcly6XFH0hGxNj9PYtC4',
	PAYME_BASE_URL: 'https://ng.paymeservice.com/api/',
	PAYME_DASHBOARD_URL: 'https://medidate.paymeservice.com',
	PAYME_REGISTER_DASHBOARD_URL: 'https://medidate.paymeservice.com/system/kyc/signup/646e1760a49aee0014c5f7af',
	PAYME_USA_REGISTER_DASHBOARD_URL: 'https://medidate.paymeservice.com/system/kyc/signup/646e1760a49aee0014c5f7b0',
	GROW_DASHBOARD_URL: 'https://meshulam.co.il/business',
	GROW_ACCOUNTING_URL: 'https://meshulam.co.il/business/document_production',
	MERCHANT_FEE_PERC:'1.0',
	SPLASH_BASE_URL: 'https://portal.splashpayments.com/',
	SPLASH_DASHBOARD_URL: 'https://portal.splashpayments.com/',
	ICOUNT_URL: 'https://api.icount.co.il/api/v3.php/',
	REMOTE_CONFIG: {},
	ISRAEL_VAT: 17,
	PAYME_KEY: "medidate2_GN4Tcjhs",
	VAS_KEY: 'VASL1651-131939ZY-VHCYKHKW-VCLNNGWR',
	APPLE_MERCHENT_ID: 'merchant.paymeproduction',//'d81e1dfd-3dd7-49ac-a157-6cf95d8f9682',//'merchant.MPL123-123123-123123-123123',
	VAS_REQUEST_URL: 'https://live.payme.io/api/vas-enable',
	// VAS_APP_URL: 'https://medidate-admin.herokuapp.com',
	VAS_APP_URL: 'https://admin.medidatewith.me',
	MAILGUN_KEY: 'key-c101ac1bf89065d49887ba4d2ef69771',
	MAILGUN_DOMAIN: 'medidatewith.me',
	TRIAL_MEMBERSHIP_ID: 'bBER8HStm6',
	YOGA_TEACHERS_ASSOCIATION_DISCOUNT: 0.1
}
