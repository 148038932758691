import moment from 'moment-timezone';
import('moment/locale/he')
// import regeneratorRuntime from 'regenerator-runtime';

// Configure moment
moment.locale('en');  // Set default locale

export default moment;

// let momentInstance = null;

// async function initializeMoment() {
//     if (momentInstance) return momentInstance;

//     try {
//         const m = await import('moment-timezone');
//         momentInstance = m.default || m;  // Handle both ESM and CommonJS formats

//         // Load all required locales at once
//         await Promise.all([
//             import('moment/locale/he')
//         ]);

//         // Set your default locale and timezone
//         momentInstance.locale('en');
//         momentInstance.tz.setDefault('UTC');
        
//         return momentInstance;
//     } catch (e) {
//         console.error('Failed to initialize moment:', e);
//         throw e;
//     }
// }

// Create a promise that will resolve to the moment instance
// const momentPromise = initializeMoment();

// Export a function that returns the promise
// export default function getMoment() {
//     return momentPromise;
// }
// // Initialize moment with required locales immediately
// async function initializeMoment() {
// 	const moment = await import('moment-timezone');  // Changed this line
// 	try {
// 		// Load all required locales at once - note the path is still 'moment/locale'
// 		await Promise.all([
// 			import('moment/locale/he'),
// 			import('moment/locale/en'),
// 			// Add any other locales you need
// 		]);

// 		// Set your default locale and timezone
// 		moment.locale('en');  // 'en' is built in
// 		moment.tz.setDefault('UTC'); // or your default timezone
// 	} catch (e) {
// 		console.error('Failed to load moment locales:', e);
// 	}
// }

// // Execute initialization
// initializeMoment();

// // Export the pre-configured moment
// export default moment;