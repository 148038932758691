export default {
	ENV: 'development',
	APP_ID: 'u2NywY9Rf6EL3ZNdfUgxqVt3ROwplSWIG00AYF2L',
	APP_URL: 'https://medidate-admin-sandbox-migrate.herokuapp.com',
	SERVER_URL: 'https://medidate-sandbox-migrate.herokuapp.com',
	PARSE_SERVER_URL: 'https://medidate-sandbox-migrate.herokuapp.com/parse/',
	LIVE_QUERY_SERVER_URL: 'wss://medidate-sandbox-migrate.herokuapp.com/parse/',
	GOOGLE_MAPS_KEY: 'AIzaSyBXe3KZgp2JghIVcly6XFH0hGxNj9PYtC4',
	PAYME_BASE_URL: 'https://preprod.paymeservice.com/api/',
	PAYME_DASHBOARD_URL: 'https://medidate.paymeservice.com',
	PAYME_REGISTER_DASHBOARD_URL: 'https://medidate.paymeservice.com/system/kyc/signup/646e1760a49aee0014c5f7af',
	PAYME_USA_REGISTER_DASHBOARD_URL: 'https://medidate.paymeservice.com/system/kyc/signup/646e1760a49aee0014c5f7b0',
	MERCHANT_FEE_PERC:'1.0',
	SPLASH_BASE_URL: 'https://test-api.splashpayments.com/',
	SPLASH_DASHBOARD_URL: 'https://test-portal.splashpayments.com/profile',
	ICOUNT_URL: 'https://api.icount.co.il/api/v3.php/',
	REMOTE_CONFIG: {},
	ISRAEL_VAT: 17,
	PAYME_KEY: "medidate_DlTocobn",
	VAS_KEY: 'VASL1651-132062FX-QKGVNMM0-JVUUO2SK',
	APPLE_MERCHENT_ID: 'd81e1dfd-3dd7-49ac-a157-6cf95d8f9682',//'merchant.MPL123-123123-123123-123123',
	VAS_REQUEST_URL: 'https://preprod.paymeservice.com/api/vas-enable',
	VAS_APP_URL: 'https://medidate-admin-sandbox-migrate.herokuapp.com',
	MAILGUN_KEY: 'key-c101ac1bf89065d49887ba4d2ef69771',
	MAILGUN_DOMAIN: 'medidatewith.me',
	TRIAL_MEMBERSHIP_ID: '7YXpeSd10U',
	YOGA_TEACHERS_ASSOCIATION_DISCOUNT: 0.1
}