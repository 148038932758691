export default () => {
	Array.prototype.insert = function(index, item) {
    this.splice( index, 0, item );
	};

	Array.prototype.containsById = function(obj){
		var i, l = this.length;
		for (i = 0; i < l; i++){
			if (this[i].id === obj.id)
			return true;
		}
		return false;
	};

	Array.prototype.containsByInnerObjectId = function(subClassKey, obj){
		var i, l = this.length;
		for (i = 0; i < l; i++){
			if (this[i].get(subClassKey).id === obj.id)
			return true;
		}
		return false;
	};

	Array.prototype.indexById = function(obj){
		var i, l = this.length;
		for (i = 0; i < l; i++){
			if (this[i].id === obj.id)
			return i;
		}
		return -1;
	};

	String.prototype.capitalize = function() {
		return this.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
	};

	String.prototype.toCurrencySign = function() {
		if (this === 'USD') return '$';
		if (this === 'ILS') return '₪';
		if (this === 'EUR') return '€';
		return '$';
	};

	Number.prototype.toNumberWithComas = function() {
		return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	String.prototype.toNumberWithComas = Number.prototype.toNumberWithComas;

	window.location.getQueryParamByName = (name) => {
		var url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}
}
