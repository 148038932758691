import Parse from 'parse';
import AppState from '../AppState'
import config from '../config';
import User from './User';
import Studio from './Studio';
import Subcategories from './Subcategories';
import Session from './Session';
import Payme from './Payme';
import Splash from './Splash';
import Plan from './Plan';
import Product from './Product';
import Noti from './Noti';
import Messages from './Messages';
import Payment from './Payment';
import PaymentRequester from './PaymentRequester';
import Accounting from './Accounting';
import { log } from './Log';

Parse.initialize(config.APP_ID);
Parse.serverURL = config.PARSE_SERVER_URL;

window.Parse = Parse;

let LiveQueryClient = Parse.LiveQueryClient;
let client = new LiveQueryClient({
	applicationId: config.APP_ID,
	serverURL: config.LIVE_QUERY_SERVER_URL
});
client.open();
window.ParseLiveQueryClient = client;

if (Parse.User.current()) {
	if (Parse.User.current().get('preferences')) {
		Parse.User.current().get('preferences').fetch().then((userPreferences) => {
			log('Fetched Preferences');
			if (Parse.User.current() && Parse.User.current().get("preferences")) {
				I18n.setLanguage(Parse.User.current().get("preferences").get("language") || 'en');

				if (Parse.User.current().get('subscription')) {
					Parse.User.current().get('subscription').fetch().then((subscription) => {
						log('Fetched Subscription');
					});
				}
			}
		});
	}

}

export const imageTypes = {
	PROFILE: 0,
	CERTIFICATE: 1,
	SESSION: 2,
	SOCIALID: 3,
	CANCELED_CHECK: 4,
	INCORPORATION_FILES: 5,
	PRODUCT: 6,
	SIGNATURE: 7
}

export const PAID_MEDIDATE_START_DATE = new Date('2019-01-01');

export function passFirstDaySubscriptionNeeded() {
	var today = new Date();
	var passSubscriptionFirstDay = (today.getTime() >= PAID_MEDIDATE_START_DATE.getTime());
	log('today', today);
	log('subscriptionFirstDay', moment(PAID_MEDIDATE_START_DATE).format('DD, MMMM YYYY'));
	log('passSubscriptionFirstDay', passSubscriptionFirstDay);
	return passSubscriptionFirstDay;
}

export const login = (email, password, callback) => {
	email = email.toLowerCase();
	log('login', email);

	try {
		Parse.User.logIn(email, password)
			.then(function (user) {
				console.log('logged in', user);
				callback(null, user);
			})
			.catch(function (error) {
				console.log('error', error);
				callback(error, null);
			});
	} catch (e) {
		log("error", e);
	}
}

export const signup = (props, callback) => {
	log("signup", props);
	var { first_name, last_name, password, email, bank_country, promotion_consent } = props
	var user = new Parse.User();
	email = email.toLowerCase();

	log("bank_country", bank_country);

	user.set({ username: email, first_name, last_name, password, email, bank_country, promotion_consent });
	user.set("approved_terms", true);

	user.signUp(null, {
		success: function (user) {
			callback(null, user);
		},
		error: function (user, error) {
			log('error', error);
			callback(error, null);
		}
	});
}

export const logout = (callback) => {
	Parse.User.logOut().then(() => {
		callback();
	});
}

export const updateUser = User.updateUser;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export const uploadImageToS3 = (props, callback) => {
	if (!props.file) {
		return;
	}

	getBase64(props.file).then((base64File) => {
		Parse.Cloud.run('uploadImage', { fileDataBase64: base64File, imageType: props.imageType }, {
			success: (res) => {
				callback(null, res)
			},
			error: (err) => {
				log(err);
				callback(err);
			}
		});
	});
}

export const uploadBase64ToS3 = (props, callback) => {
	if (!props.base64) {
		return;
	}

	// getBase64(props.file).then((base64File) => {
	Parse.Cloud.run('uploadImage', { fileDataBase64: props.base64, imageType: props.imageType }, {
		success: (res) => {
			callback(null, res)
		},
		error: (err) => {
			log(err);
			callback(err);
		}
	});
	// });
}

export const uploadProfileImage = (file, callback) => {
	uploadImageToS3({ file, imageType: imageTypes.PROFILE }, function (err, res) {
		if (err) {
			if (callback) callback(err, null);
			return;
		}
		updateUser({ 'user_image_url': res }, (error, user) => {
			if (callback) callback(error, res);
		})
	});
}

export const uploadOtherUserProfileImage = (user, file, callback) => {
	uploadImageToS3({ file, imageType: imageTypes.PROFILE }, function (err, res) {
		if (err) {
			if (callback) callback(err, null);
			return;
		}
		var params = { userId: user.id, 'user_image_url': res }
		Parse.Cloud.run('saveOtherUser', params, {
			success: (res) => {
				log('success saveOtherUser');
				log(res.get('user_image_url'));
				if (callback) callback(null, res.get('user_image_url'));
			},
			error: (err) => {
				log('error', err);
				if (callback) callback(error, null);
			}
		})
	});
}

export const requestPasswordReset = (email, callback) => {
	email = email.toLowerCase();
	Parse.User.requestPasswordReset(email, {
		success: function () {
			callback(null)
		},
		error: function (error) {
			callback(error)
		}
	});
}


var BANANNA_KING_CONF_KEYS = {
	PAYME_CLIENT_KEY: config.ENV === 'development' ? 'bkc' : 'bkcp',
	SPLASH_CLIENT_KEY: config.ENV === 'development' ? 'bkspl' : 'bksplp'
}

var setBananaKingConfigs = () => {
	// this needs to be replaced
	var BanannaKing = Parse.Object.extend('BanannaKing');
	var query = new Parse.Query(BanannaKing);
	query.startsWith('title', 'b');

	query.find({
		success: function (results) {
			var obj = {};
			results.map((item) => {
				if (!item.get('title') || !item.get('key')) {
					return;
				}
				obj[item.get('title')] = item.get('key');
			})

			config.PAYME_CLIENT_KEY = obj[BANANNA_KING_CONF_KEYS.PAYME_CLIENT_KEY];
			config.SPLASH_CLIENT_KEY = obj[BANANNA_KING_CONF_KEYS.SPLASH_CLIENT_KEY];

		},
		error: function (error) {
			log(error);
		}
	});
}


var setConfigConfigs = () => {
	var Config = Parse.Object.extend('Config');
	var query = new Parse.Query(Config);
	query.lessThan('createdAt', new Date());

	query.find({
		success: function (results) {
			var obj = {};
			results.map((item) => {
				if (!item.get('title') || !item.get('value')) {
					return;
				}
				obj[item.get('title')] = item.get('value');
			})
			config.REMOTE_CONFIG = obj;

		},
		error: function (error) {
			log(error);
		}
	});
}

function isParseUserSessionTokenValid(callback) {
	return new Promise((resolve, reject) => {
		log('isParseUserSessionTokenValidVar', AppState.get('isParseUserSessionTokenValidVar'));

		if (!Parse.User.current()) {
			log('!Parse.User.current()', !Parse.User.current());
			callback(true, false)
			resolve(false)
			return;
		}
		var sessionToken = Parse.User.current().getSessionToken();
		log('isParseUserSessionTokenValidVar sessionToken', sessionToken);
		log('isParseUserSessionTokenValidVar isLoggedIn', AppState.get('isLoggedIn'));
		if (!AppState.get('isLoggedIn') && !sessionToken) {
			callback(true, false)
			resolve(false)
			return;
		}

		if (!sessionToken) {
			callback(true, false)
			resolve(false)
			return;
		} else {
			try {
				Parse.User.become(sessionToken).then(function (user) {
					// Do something now that we have a Parse.User stored in the "user" var
					log('isParseUserSessionTokenValid sessionToken Valid');
					callback(false, true)
					resolve(true)
					return;
				}, function (error) {
					log('isParseUserSessionTokenValid sessionToken NOT Valid: ' + error);
					callback(true, false)
					resolve(false)
				});
			} catch (error) {
				log('isParseUserSessionTokenValid sessionToken NOT Valid: ' + error);
				callback(true, false)
				resolve(false)
			}
		}
	});
}

function clearParseUserSessionTokenValidVar(callback) {
	try {
		log('isParseUserSessionTokenValid clearParseUserSessionTokenValidVar');
		AppState.set('isParseUserSessionTokenValidVar', false);
		AppState.set('userId', null);
		AppState.set('isLoggedIn', null);
		if (Parse.User.current()) {
			Parse.User.logOut().then(() => {
				log('isParseUserSessionTokenValid logOut');
				callback()
			}, function (error) {
				log('isParseUserSessionTokenValid logOut error: ' + error);
				callback()
			});
		} else {
			callback()
		}
		// window.location.href = '/#/login';
		// window.location.reload();
	} catch (error) {
		callback()
		log('isParseUserSessionTokenValid error: ' + error);
	}
}

export const setRemoteConfigs = () => {
	// this needs to be replaced with a function from server
	setBananaKingConfigs();
	setConfigConfigs();

}

export default {
	login,
	signup,
	logout,
	requestPasswordReset,
	updateUser,
	uploadProfileImage,
	uploadOtherUserProfileImage,
	isParseUserSessionTokenValid,
	clearParseUserSessionTokenValidVar,
	User,
	Subcategories,
	Studio,
	Session,
	uploadImageToS3,
	uploadBase64ToS3,
	imageTypes,
	Payme,
	Splash,
	setRemoteConfigs,
	Plan,
	Product,
	Noti,
	Messages,
	Payment,
	PaymentRequester,
	Accounting,
	passFirstDaySubscriptionNeeded,
	PAID_MEDIDATE_START_DATE
}
