import React from 'react';

const LoadingFallback = () => (
  <div className="text-align:center">
    <h1 className="mb-5" 
      style={{
        position: 'absolute',
        margin: 'auto',
        top: '75%',
        left: 0,
        right: 0,
        bottom: 0,
        direction: 'ltr',
        textAlign: 'center',
        color: '#009cff',
        fontFamily: 'Montserrat, sans-serif'
      }} 
      id="loadingText">Loading Medidate...</h1>
    <img 
      height="150" 
      style={{
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        direction: 'ltr'
      }} 
      src="img/medidate_loader_trans.gif" 
    />
  </div>
);

export default LoadingFallback;